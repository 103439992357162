(function($) {

    // functions 

    function add_fake_button_file_upload(){

        var html = '<div class="file-upload__button__wrapper"><span class="filename">' + vtx_form_strings.file_upload_no_file_selected + '</span><span class="btn s02">' + vtx_form_strings.file_upload_button_label + '</span></div>';

        $('.ginput_container_fileupload').each(function(){
            var container = $(this);
            var fileInput = container.find('input[type="file"]');
            fileInput.after(html);

            fileInput.change(function(){
                var text = $(this).val().replace(/C:\\fakepath\\/i, '');
                if(text === ''){
                    text = vtx_form_strings.file_upload_no_file_selected;
                }
                container.find('.file-upload__button__wrapper > .filename').text( text );
            });
            
        });
    }

    function change_checkbox_clicking_area(){
        $('.big__checkboxes .gfield_checkbox li').each(function(){

            $(this).on('click' , function(){
                if($(this).hasClass('checked')){
                    $(this).removeClass('checked');
                    $(this).find('input[type="checkbox"]').prop('checked', false);
                }else{
                    $(this).addClass('checked');
                    $(this).find('input[type="checkbox"]').prop('checked', true);
                }
            });

            $(this).find('input[type="checkbox"]').on('click' , function(e){
                $(this).prop('checked', !$(this).prop('checked'));
            });

        });
    }


    $(document).ready(function(){

        // document ready
        if( $('.ginput_container_fileupload').length > 0 ){

            add_fake_button_file_upload();

            $('iframe[id^="gform_ajax_frame_"').on('load' , function(){
                add_fake_button_file_upload();
            });
            
        }

        if( $('.gfield_checkbox li').length > 0 ){
            change_checkbox_clicking_area();
        }

        if( $('.ginput_recaptcha').length > 0 ){
            $('.ginput_recaptcha').parents('.gform_wrapper').addClass('has-recaptcha');
        }

    });
    
    
    })(jQuery);